<template>
  <div>
    <b-row class="mt-3 mb-3">
      <b-col sm="12">
        <h5 class="text-uppercase font-weight-bold">
          {{ $t('orderModal.sections.orderpurchaseOptions.title') }}
        </h5>
      </b-col>
    </b-row>

    <b-row style="align-items: center;">
      <b-col sm="12" md="12">
        <b-button :disabled="readonly" variant="primary" @click="newLine()">
          <i class="fa fa-plus" />
          Novo Valor
        </b-button>
      </b-col>
      <b-col v-if="formData.purchaseOptions" sm="12">
        <b-row v-for="(value, index) in formData.purchaseOptions" :key="index" style="width: 100%; align-items: center;">
          <b-col sm="12" md="3" class="mt-sm-3">
            <b-form-group
              :label="$t('orderModal.sections.orderpurchaseOptions.label.period')"
              label-for="letter-of-period-input"
            >
              <b-input-group prepend="Mês">
                <b-form-input
                  id="letter-of-period-input"
                  type="number"
                  :readonly="readonly"
                  :state="checkFieldResidual('purchaseOptions', index, 'period')"
                  :value="value.period"
                  @input="(value) => changePeriod(index, parseFloat(value))"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="3" class="mt-sm-3">
            <b-form-group
              :label="$t('orderModal.sections.orderpurchaseOptions.label.purchasePrice')"
              label-for="letter-to-purchase-price"
            >
              <b-input-group :prepend="$t('orderModal.labels.money')">
                <money
                  id="letter-to-purchase-price"
                  v-model="value.purchaseValue"
                  class="form-control"
                  data-test="letter-to-purchase-price"
                  v-bind="money"
                  :class="checkMoneyResidualState('purchaseOptions', index, 'purchaseValue')"
                  :readonly="readonly"
                  :value="moneyInput(value.purchaseValue)"
                  @input="value.purchaseValue"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col v-if="!readonly" class="mt-sm-3">
            <b-button variant="danger" @click="removeItem(index)">
              <i class="fa fa-trash" />
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { orderInputsMixin } from '@components/modals/orders/orderInputsMixin';

export default {
  name: 'OrderpurchaseOptions',
  mixins: [orderInputsMixin],
  mounted() {
    this.formData?.purchaseOptions?.sort((a, b) => {
      return a.period < b.period ? -1 : a.period > b.period ? 1 : 0;
    })
  },
  methods: {
    newLine() {
      if (!this.formData.purchaseOptions) this.formData.purchaseOptions = []

      this.formData.purchaseOptions.unshift({
        period: 0,
        purchaseValue: 0
      })
      console.log(this.formData.purchaseOptions)
    },
    removeItem(indexValue) {
      this.formData.purchaseOptions = this.formData.purchaseOptions.filter((_, index) => index !== indexValue)
    },
    changePeriod(index, value) {
      this.formData.purchaseOptions[index].period = value
    }
  }
}
</script>

<style scoped>

</style>
