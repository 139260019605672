var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "prodivers-list" },
    [
      _c("custom-data-table", {
        ref: "CustomDataTable",
        attrs: {
          fields: _vm.fields,
          filters: _vm.filters,
          query: _vm.contract_get_query,
          "query-iterator": "orders",
          "query-variables": { id: _vm.$route.params.contract_id },
          "query-node": "contract",
        },
        on: { "vuetable:row-clicked": _vm.$_goTo },
        scopedSlots: _vm._u([
          {
            key: "order_number",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    _vm._f("moment")(props.rowData.created_at, "YYYYMMDDHHmmss")
                  )
                ),
              ])
            },
          },
          {
            key: "created_at",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(
                    _vm._f("moment")(props.rowData.created_at, "DD/MM/YYYY")
                  )
                ),
              ])
            },
          },
          {
            key: "period",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(props.rowData.period) +
                    " " +
                    _vm._s(props.rowData.period > 1 ? "meses" : "mês")
                ),
              ])
            },
          },
          {
            key: "delivered",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(" " + _vm._s(props.rowData.total_cars) + " "),
              ])
            },
          },
          {
            key: "status",
            fn: function (props) {
              return _c(
                "span",
                {},
                [
                  _c(
                    "b-badge",
                    {
                      attrs: {
                        variant: _vm.formatStatus(props.rowData.status),
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            `providers.status.${props.rowData.status.toLowerCase()}`
                          )
                        )
                      ),
                    ]
                  ),
                ],
                1
              )
            },
          },
          {
            key: "version",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(" " + _vm._s(props.rowData.revision) + " "),
              ])
            },
          },
          {
            key: "actions",
            fn: function (props) {
              return _c("span", {}, [
                _c(
                  "div",
                  [
                    _vm._v("   "),
                    _c(
                      "b-dropdown",
                      {
                        attrs: {
                          size: "sm",
                          variant: "link",
                          "toggle-class": "text-decoration-none",
                          "no-caret": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "button-content",
                              fn: function () {
                                return [_c("i", { staticClass: "fa fa-bars" })]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "mt-2",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.openModalUpdateOrder(props.rowData)
                              },
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "#20a8d8" } }, [
                              _c("i", {
                                staticClass: "fa fa-edit",
                                staticStyle: { color: "#20a8d8" },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("providers.titles.editContract")
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                        _c("b-dropdown-divider"),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "mb-2",
                            attrs: { variant: "link" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.openModalpurchaseOptions(
                                  props.rowData
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4dbd74",
                                  "padding-bottom": "15px",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-money",
                                  staticStyle: { color: "#4dbd74" },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "providers.titles.editpurchaseOptions"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            },
          },
        ]),
      }),
      _c("order-modal", {
        ref: "orderModal",
        attrs: {
          "contract-id": _vm.$route.params.contract_id,
          "form-mode": _vm.formMode,
          order: _vm.order,
        },
        on: {
          update: _vm.setUpdateMode,
          hidden: _vm.modalHidden,
          success: _vm.refreshResults,
        },
      }),
      _c("purchase-option-modal", {
        ref: "purchaseOptionsModal",
        attrs: {
          "contract-id": _vm.$route.params.contract_id,
          "form-mode": _vm.formMode,
          order: _vm.order,
        },
        on: {
          update: _vm.setUpdateMode,
          hidden: _vm.modalHidden,
          success: _vm.refreshResults,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }