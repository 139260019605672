<template>
  <div class="prodivers-list">
    <custom-data-table
      ref="CustomDataTable"
      :fields="fields"
      :filters="filters"
      :query="contract_get_query"
      :query-iterator="'orders'"
      :query-variables="{ id: $route.params.contract_id }"
      query-node="contract"
      @vuetable:row-clicked="$_goTo"
    >
      <span
        slot="order_number"
        slot-scope="props"
      >{{ props.rowData.created_at | moment('YYYYMMDDHHmmss') }}</span>
      <span
        slot="created_at"
        slot-scope="props"
      >{{ props.rowData.created_at | moment('DD/MM/YYYY') }}</span>
      <span
        slot="period"
        slot-scope="props"
      >{{ props.rowData.period }} {{ props.rowData.period > 1 ? 'meses' : 'mês' }}</span>
      <span slot="delivered" slot-scope="props">
        {{ props.rowData.total_cars }}
      </span>
      <span slot="status" slot-scope="props">
        <b-badge
          :variant="formatStatus(props.rowData.status)"
        >{{ $t(`providers.status.${props.rowData.status.toLowerCase()}`) }}</b-badge>
      </span>
      <span slot="version" slot-scope="props">
        {{ props.rowData.revision }}
      </span>
      <span slot="actions" slot-scope="props">
        <div>
          &nbsp;
          <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none"
                      no-caret
          >
            <template #button-content>
              <i class="fa fa-bars" />
            </template>
            <b-dropdown-item class="mt-2" @click.stop="openModalUpdateOrder(props.rowData)">
              <span style="color: #20a8d8">
                <i class="fa fa-edit" style="color: #20a8d8" /> {{ $t('providers.titles.editContract') }}
              </span>
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item class="mb-2" variant="link" @click.stop="openModalpurchaseOptions(props.rowData)">
              <span style="color: #4dbd74; padding-bottom: 15px;">
                <i class="fa fa-money" style="color: #4dbd74" /> {{ $t('providers.titles.editpurchaseOptions') }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </span>
    </custom-data-table>

    <order-modal
      ref="orderModal"
      :contract-id="$route.params.contract_id"
      :form-mode="formMode"
      :order="order"
      @update="setUpdateMode"
      @hidden="modalHidden"
      @success="refreshResults"
    />

    <purchase-option-modal
      ref="purchaseOptionsModal"
      :contract-id="$route.params.contract_id"
      :form-mode="formMode"
      :order="order"
      @update="setUpdateMode"
      @hidden="modalHidden"
      @success="refreshResults"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatVat } from '@utils/numbers';
import { contractStatusEnum } from '@utils/provider';
import { formatStatus } from '@utils/contract';
import CONTRACT_GET from '@graphql/contract/queries/get.gql';
import CustomDataTable from '@components/CustomDataTable';
import OrderModal from '@components/modals/orders/orderModal';
import purchaseOptionModal from '@components/modals/orders/purchaseOptionModal';
import transformObjectKeys from 'transform-object-keys';

export default {
  name: 'ContractTable',
  components: {
    CustomDataTable,
    OrderModal,
    purchaseOptionModal,
  },
  data() {
    return {
      formMode: 'create',
      order: {},
      contract_get_query: CONTRACT_GET,
      fields: [
        {
          name: 'order_number',
          title: 'Nº Pedido',
        },
        {
          name: 'volume',
          title: this.$t('providers.tables.carsNumbers'),
          sortField: 'volume',
        },
        {
          name: 'delivered',
          title: this.$t('providers.tables.carsDelivereds'),
        },
        {
          name: 'period',
          title: this.$t('providers.tables.period'),
          sortField: 'period',
        },
        {
          name: 'created_at',
          title: this.$t('providers.tables.createdAtDate'),
          sortField: 'created_at',
        },
        {
          name: 'status',
          title: this.$t('providers.tables.status'),
          sortField: 'status',
        },
        {
          name: 'version',
          title: this.$t('providers.tables.version'),
          sortField: 'revision',
        },
        {
          name: 'actions',
          title: this.$t('providers.tables.actions'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getContract: 'provider/getContract',
      getOrder: 'provider/getOrder',
    }),
    filters() {
      return {
        status: {
          type: 'select',
          cols: 2,
          props: {
            label: 'Status',
            options: [
              {
                value: null,
                text: this.$t('providers.tables.optionAll'),
              },
              ...contractStatusEnum,
            ],
          },
          graphqlQuery(value) {
            return !value
              ? { status: contractStatusEnum.map(item => item.value) }
              : { status: [value] };
          },
        },
      };
    },
  },
  methods: {
    ...mapActions({
      setContract: 'provider/setContract',
      setOrder: 'provider/setOrder',
    }),
    modalHidden() {
      this.formMode = "create";
      this.order = {}
    },
    setUpdateMode() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.formMode = 'update';
    },
    setVatFormat(vat) {
      return formatVat(vat);
    },
    $_goTo(_item = null) {
      _item = _item.data;
      this.setOrder({
        id: _item.id,
      });
      this.$router.push(
        `/providers/contract/${this.$route.params.contract_id}/order/${_item.id}`
      );
    },
    setDecimal(value) {
      return (value / 100).toFixed(2);
    },
    openModalUpdateOrder(item) {
      this.formMode = 'read'
      this.order = transformObjectKeys(item, { deep: true });
      this.$bvModal.show('order-modal');
    },
    openModalpurchaseOptions(item) {
      this.formMode = 'read'
      this.order = transformObjectKeys(item, { deep: true });
      this.$bvModal.show('purchase-option-modal');
    },
    refreshResults() {
      this.$refs.CustomDataTable.$_refresh();
    },
    formatStatus,
  },
};
</script>

<style lang="sass" scoped>
.edit-button
  padding: 0
  margin: 0
.badge
  padding: 5px 10px
  color: white
  &-ACTIVE
    background: green
  &-PENDING
    background: orange
  &-CLOSED
    background: red
</style>
