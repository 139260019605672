import { render, staticRenderFns } from "./contract-header.vue?vue&type=template&id=7a94e5a7&scoped=true&"
import script from "./contract-header.vue?vue&type=script&lang=js&"
export * from "./contract-header.vue?vue&type=script&lang=js&"
import style0 from "./contract-header.vue?vue&type=style&index=0&id=7a94e5a7&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a94e5a7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/seed/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7a94e5a7')) {
      api.createRecord('7a94e5a7', component.options)
    } else {
      api.reload('7a94e5a7', component.options)
    }
    module.hot.accept("./contract-header.vue?vue&type=template&id=7a94e5a7&scoped=true&", function () {
      api.rerender('7a94e5a7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/provider-contract/contract-header.vue"
export default component.exports