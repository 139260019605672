import { BModal } from 'bootstrap-vue'
import OrderpurchaseOption from './sections/orderPurchaseOption.vue'
import {
  update as updateOrderService,
} from '@graphql/order/services';
import { validationMixin } from 'vuelidate';
import orderInputsObject from '@components/modals/orders/orderInputsObject';
import orderValidations from '@components/modals/orders/orderValidations';
import omit from 'lodash/omit'
export default {
  name: 'OrderModal',
  mixins: [validationMixin],
  data() {
    return {
      submitClick: false,
      formData: {}
    };
  },
  props: {
    contractId: {
      type: String,
      default: null,
    },
    order: {
      type: Object,
      default: () => ({}),
    },
    formMode: {
      type: String,
      default: 'create',
    }
  },
  computed: {
    modalTitle() {
      return this.$t(`orderModal.modalTitle.${this.formMode}`);
    },
    cancelButton() {
      return this.$t('orderModal.buttons.cancel');
    },
    modalButton() {
      return this.$t(`orderModal.modalButton.${this.formMode}`);
    },
    readonly() {
      return this.formMode === 'read'
    },
    childrenProps() {
      return {
        props: {
          contractId: this.contractId,
          formData: (this.formMode === 'create') ? this.formData : this.order,
          formMode: this.formMode,
          readonly: this.readonly,
          submitClick: this.submitClick,
          validator: this.$v,
        },
        on: {
          change: this.inputHandler,
        },
      }
    },
  },
  beforeMount() {
    this.setFormData()
  },
  validations: {
    ...orderValidations
  },
  methods: {
    setFormData() {
      this.formData = (this.formMode === 'create')
        ? { ...orderInputsObject }
        : this.order;

      if (this.contractId) this.formData.contract = this.contractId;
    },
    inputHandler({ name, data }) {
      this.formData[name] = data;
      this.$v.formData[name]?.$touch();
    },
    hideHandler() {
      this.$emit('hidden');
      this.submitClick = false;
      this.setFormData();
    },
    async submitHandler(evt) {
      evt.preventDefault();

      if (this.formMode === 'read') {
        this.$emit('update');
        return;
      }

      this.submitClick = true;
      this.$v.$touch();

      if (this.$v.$invalid) return;

      try {
        this.formData?.purchaseOptions?.sort((a, b) => {
          return a.period < b.period ? -1 : a.period > b.period ? 1 : 0;
        })
        if (this.formMode === 'update') await updateOrderService({
          orderId: this.formData.id,
          orderInput: omit(this.formData, ['id', 'revision', 'revisionObs', 'typename']),
          revisionObs: this.formData.revisionObs
        });

        await this.$swal({
          type: 'success',
          title: this.$t(`orderModal.alerts.success.${this.formMode}`),
          text: '',
        });

        this.$emit('success');
        this.$bvModal.hide('purchase-option-modal');
        this.hideHandler();
      } catch (e) {
        await this.$swal({
          type: 'error',
          title: this.$t(`orderModal.alerts.error.${this.formMode}`),
          text: e,
        });
      }
    },
  },
  render(h) {
    return h(
      BModal,
      {
        attrs: {
          id: 'purchase-option-modal',
        },
        props: {
          okTitle: this.modalButton,
          cancelTitle: this.cancelButton,
          title: this.modalTitle,
          size: 'xl',
        },
        on: {
          hidden: this.hideHandler,
          cancel: this.hideHandler,
          ok: this.submitHandler,
          shown: this.setFormData,
        }
      },
      [
        h(
          OrderpurchaseOption,
          { ...this.childrenProps },
        ),
      ]
    )
  }
}
