var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mt-3 mb-3" },
        [
          _c("b-col", { attrs: { sm: "12" } }, [
            _c("h5", { staticClass: "text-uppercase font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("orderModal.sections.orderpurchaseOptions.title")
                  ) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticStyle: { "align-items": "center" } },
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "12" } },
            [
              _c(
                "b-button",
                {
                  attrs: { disabled: _vm.readonly, variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.newLine()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" Novo Valor ")]
              ),
            ],
            1
          ),
          _vm.formData.purchaseOptions
            ? _c(
                "b-col",
                { attrs: { sm: "12" } },
                _vm._l(_vm.formData.purchaseOptions, function (value, index) {
                  return _c(
                    "b-row",
                    {
                      key: index,
                      staticStyle: { width: "100%", "align-items": "center" },
                    },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "mt-sm-3",
                          attrs: { sm: "12", md: "3" },
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "orderModal.sections.orderpurchaseOptions.label.period"
                                ),
                                "label-for": "letter-of-period-input",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                { attrs: { prepend: "Mês" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "letter-of-period-input",
                                      type: "number",
                                      readonly: _vm.readonly,
                                      state: _vm.checkFieldResidual(
                                        "purchaseOptions",
                                        index,
                                        "period"
                                      ),
                                      value: value.period,
                                    },
                                    on: {
                                      input: (value) =>
                                        _vm.changePeriod(
                                          index,
                                          parseFloat(value)
                                        ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "mt-sm-3",
                          attrs: { sm: "12", md: "3" },
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "orderModal.sections.orderpurchaseOptions.label.purchasePrice"
                                ),
                                "label-for": "letter-to-purchase-price",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                {
                                  attrs: {
                                    prepend: _vm.$t("orderModal.labels.money"),
                                  },
                                },
                                [
                                  _c(
                                    "money",
                                    _vm._b(
                                      {
                                        staticClass: "form-control",
                                        class: _vm.checkMoneyResidualState(
                                          "purchaseOptions",
                                          index,
                                          "purchaseValue"
                                        ),
                                        attrs: {
                                          id: "letter-to-purchase-price",
                                          "data-test":
                                            "letter-to-purchase-price",
                                          readonly: _vm.readonly,
                                          value: _vm.moneyInput(
                                            value.purchaseValue
                                          ),
                                        },
                                        on: { input: value.purchaseValue },
                                        model: {
                                          value: value.purchaseValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              value,
                                              "purchaseValue",
                                              $$v
                                            )
                                          },
                                          expression: "value.purchaseValue",
                                        },
                                      },
                                      "money",
                                      _vm.money,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.readonly
                        ? _c(
                            "b-col",
                            { staticClass: "mt-sm-3" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "danger" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeItem(index)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa fa-trash" })]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }