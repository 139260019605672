import sumBy from 'lodash/sumBy';

export const countVolume = total => {
  return sumBy(total, 'total_cars');
};

export const countVolumeMobilized = total => {
  return sumBy(total, 'total_cars_mobilized');
};

export const countMonthPrice = total => {
  if (!Array.isArray(total)) return;

  const sum = sumBy(total, 'pricing_amount');

  const min = total.reduce((_prev, current) => {
    if (!current.safecar_included || !current.tracker_included || !current.rcf_included) {
      return 0;
    }
    return current.safecar_included + current.tracker_included + current.rcf_included;
  }, 0);
  return sum - min;
};

export const formatStatus = status => {
  return status === 'ACTIVE'
    ? 'success'
    : status === 'CLOSED'
      ? 'secondary'
      : status === 'PENDING'
        ? 'warning'
        : 'primary';
};

export const enumStatus = [
  { text: 'Ativo', value: 'ACTIVE' },
  { text: 'Encerrado', value: 'CLOSED' },
  { text: 'Pendente', value: 'PENDING' },
];
