var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("h2", [_vm._v(_vm._s(_vm.$t("providers.titles.requests")))]),
          ]),
          _c(
            "b-col",
            { staticClass: "d-flex flex-row-reverse", attrs: { cols: "6" } },
            [
              _c("b-button", { on: { click: _vm.newOrder } }, [
                _vm._v(" " + _vm._s(_vm.$t("providers.header.newOrder")) + " "),
              ]),
              _c(
                "b-button",
                { staticClass: "mr-2", on: { click: _vm.backHistory } },
                [_vm._v(" " + _vm._s(_vm.$t("providers.header.return")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", { staticClass: "d-flex", attrs: { cols: "6" } }, [
            _c("div", { staticClass: "box-breadcrumb" }, [
              _c("p", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(_vm.$t("providers.header.provider")) + " "),
              ]),
              _vm._v(" " + _vm._s(_vm.contract.company_name) + " "),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }