import { i18next } from '../translate/i18n';

export const contractStatusEnum = [
  {
    text: i18next.t('providers.status.active'),
    value: 'ACTIVE'
  },
  {
    text: i18next.t('providers.status.inactive'),
    value: 'INACTIVE'
  }
];
