<template>
  <Layout>
    <contract-header @newOrderAdded="handleNewOrderAdded" />
    <contract-table ref="contractTable" />
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
// import ContentControll from '@components/content-controll';
// import ContentLoading from '@components/content-loading';
import ContractHeader from '@components/provider-contract/contract-header';
import ContractTable from '@components/provider-contract/contract-table';
export default {
  name: 'ProvidersContracts',
  components: {
    Layout,
    // ContentControll,
    // ContentLoading,
    ContractHeader,
    ContractTable,
  },
  methods: {
    handleNewOrderAdded() {
      this.$refs.contractTable.$refs.CustomDataTable.$_refresh();
    },
  },
};
</script>
